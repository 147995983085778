import React from "react";

function ModalDetailedReport({
  count,
  totalCount,
  stuData,
  getReports,
  handlePrint,
}) {
  const countData = ["1-30", "31-60", "61-90", "91-120", "121-150"];

  var arr;
  if (count) {
    arr = Array(count).fill(-1);
  } else {
    arr = Array(count).fill(-1);
  }

  const printMarkSheet = (key) => {
    // if (key == 0) {
    //   handlePrint(key);
    //   return;
    // }

    const arr1 = [];
    let i = key * 30;
    for (let j = 0; j < 30; j++) {
      arr1.push(stuData[i]?.student_id);
      i++;
    }
    getReports(key, arr1);
  };

  const printMarkSheet1 = (key) => {
    const arr1 = [];
    let i = key * 30;
    for (let j = 0; j < totalCount % 30; j++) {
      arr1.push(stuData[i]?.student_id);
      i++;
    }
    getReports(key, arr1);
  };

  return (
    <div
      className="modal fade"
      id="reportDetailed"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
              Detailed marksheet of students
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            {arr?.map((item, key) => {
              return (
                <button
                  className="btn btn-success p-2 ml-2"
                  onClick={() => {
                    printMarkSheet(key);
                  }}
                >
                  Print {countData[key]}
                </button>
              );
            })}
            <button
              className="btn btn-success p-2 ml-2"
              onClick={() => {
                printMarkSheet1(count);
              }}
            >
              Print {count * 30 + 1} - {totalCount}
            </button>
          </div>
        </div>

        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}

export default ModalDetailedReport;
