import React from "react";
import { useState } from "react";

function EnggResults({
  timeTableData,
  studentData,
  setX,
  x,
  examMarks,
  internal,
  studentLoadingMsg,
}) {
  const gparef = [
    {
      min: 85,
      max: 100,
      grade: "AA",
      gp: 10,
    },
    {
      min: 75,
      max: 84.99,
      grade: "AB",
      gp: 9,
    },
    {
      min: 65,
      max: 74.99,
      grade: "BB",
      gp: 8,
    },
    {
      min: 55,
      max: 64.99,
      grade: "BC",
      gp: 7,
    },
    {
      min: 45,
      max: 54.99,
      grade: "CC",
      gp: 6,
    },
    {
      min: 40,
      max: 44.99,
      grade: "CD",
      gp: 5,
    },
    {
      min: 35,
      max: 39.99,
      grade: "DD",
      gp: 4,
    },
    {
      min: 0,
      max: 34.99,
      grade: "FF",
      gp: 0,
    },
  ];

  const maptoHundred = (num, max) => (100 * Number(num)) / Number(max);

  const calculateGradePointEachSub = (num, max) => {
    // console.log(d);
    let marks = maptoHundred(num, max);
    for (const i of gparef) {
      if (i.min <= marks && i.max >= marks) {
        // console.log(i, marks);
        return i;
      }
    }
  };

  return (
    <table
      id="table_id"
      className="table table-bordered"
      style={{
        borderCollapse: "collapse",
        borderSpacing: 0,
        width: "100%",
      }}
    >
      <thead>
        <tr>
          <th>Sl. No.</th>
          <th>Reg. No.</th>
          <th>Full Name</th>
          {timeTableData &&
            timeTableData.map((data, index) => {
              const subjectName = examMarks?.courses?.find(
                (s) => s.id == data?.course_id
              )?.name;
              return (
                <th key={index} colSpan="2">
                  {subjectName}
                </th>
              );
            })}
          <th>Obtained Marks</th>
          <th>Total Marks</th>
          <th>Percentage</th>
          <th>Action</th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th></th>
          {timeTableData &&
            timeTableData.map((_, index) => {
              return (
                <>
                  <th key={`th-${index}`}>Theory</th>
                  <th key={`pr-${index}`}>Practical</th>
                </>
              );
            })}
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        {studentData && studentData?.length != 0
          ? studentData?.map((data, key) => {
              return (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td> {data?.user_id}</td>
                  <td> {data?.name}</td>
                  {timeTableData &&
                    timeTableData?.map((i) => {
                      const d = examMarks?.postMarks?.find(
                        (s) =>
                          s?.time_table_id == i?.id &&
                          s?.student_id == data?.user_id
                      );
                      const midterm = examMarks?.midtermMarks?.find(
                        (s) =>
                          s.course_id == i?.course_id &&
                          s?.student_id == data?.user_id
                      );
                      const e = examMarks?.internalMarks?.find(
                        (s) =>
                          s.course_id ==
                            examMarks?.courses?.find(
                              (s1) =>
                                s1.name ==
                                  examMarks?.courses?.find(
                                    (s2) => s2.id == i?.course_id
                                  )?.name && s1.type == "PRACTICAL"
                            )?.id && s.student_id == data?.user_id
                      );
                      const viva = examMarks?.vivaMarks?.find(
                        (s) =>
                          s.course_id ==
                            examMarks?.courses?.find(
                              (s1) =>
                                s1.name ==
                                  examMarks?.courses?.find(
                                    (s2) => s2.id == i?.course_id
                                  )?.name && s1.type == "PRACTICAL"
                            )?.id && s.student_id == data?.user_id
                      );
                      return (
                        <>
                          <td>
                            {d ? (
                              d?.absent ? (
                                <span className="badge badge-soft-danger">
                                  Absent
                                </span>
                              ) : (
                                d?.marks +
                                midterm?.marks +
                                "-" +
                                calculateGradePointEachSub(
                                  d?.marks + midterm?.marks,
                                  i?.max_marks +
                                    examMarks?.courses?.find(
                                      (s) =>
                                        s.id == i?.course_id &&
                                        s.type == "LECTURE"
                                    )?.midterm_max_marks
                                )?.grade
                              )
                            ) : (
                              <span className="badge badge-soft-warning">
                                N/A
                              </span>
                            )}
                          </td>
                          <td>
                            {e ? (
                              e?.absent ? (
                                <span className="badge badge-soft-danger">
                                  Absent
                                </span>
                              ) : (
                                e?.marks +
                                viva?.marks +
                                "-" +
                                calculateGradePointEachSub(
                                  e?.marks + viva?.marks,
                                  e?.max_marks +
                                    examMarks?.courses?.find(
                                      (s1) =>
                                        s1.name ==
                                          examMarks?.courses?.find(
                                            (s2) => s2.id == i?.course_id
                                          )?.name && s1.type == "PRACTICAL"
                                    )?.viva_max_marks
                                )?.grade
                              )
                            ) : (
                              <span className="badge badge-soft-warning">
                                N/A
                              </span>
                            )}
                          </td>
                        </>
                      );
                    })}
                  <td>
                    {examMarks?.postMarks
                      ?.filter(
                        (s) =>
                          s?.student_id == data?.user_id &&
                          timeTableData?.some((t) => t.id == s.time_table_id)
                      )
                      ?.reduce((sum, cur) => sum + cur?.marks, 0) +
                      examMarks?.midtermMarks
                        ?.filter((s) => s.student_id == data?.user_id)
                        ?.reduce((acc, it) => acc + it?.marks, 0) +
                      examMarks?.internalMarks
                        ?.filter((s) => s.student_id == data?.user_id)
                        ?.reduce((acc, curr) => acc + curr?.marks, 0) +
                      examMarks?.vivaMarks
                        ?.filter((s) => s.student_id == data?.user_id, 0)
                        ?.reduce((acc, curr) => acc + curr?.marks, 0)}
                  </td>
                  <td>
                    {timeTableData
                      ?.filter(
                        (t) =>
                          t.id ==
                          examMarks?.postMarks?.find(
                            (s) =>
                              s.student_id == data?.user_id &&
                              t.id == s.time_table_id
                          )?.time_table_id
                      )
                      ?.reduce((acc, i) => acc + i?.max_marks, 0) +
                      internal
                        ?.filter((s) => s.student_id == data?.user_id)
                        ?.reduce((acc, curr) => acc + curr?.max_marks, 0) +
                      examMarks?.totalMarksFromCourseTable}
                  </td>
                  <td>
                    {
                      examMarks?.percentageArray?.find(
                        (s) => s.student_id == data?.user_id
                      )?.percentage
                    }
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      readOnly={
                        examMarks?.postMarks?.find(
                          (s) => s.student_id == data?.user_id
                        )?.status == "PUBLISHED"
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          let arr = [...x];
                          arr.push(data?.user_id);
                          setX(arr);
                        } else {
                          let newArr = x?.filter((s) => s != data?.user_id);
                          setX(newArr);
                        }
                      }}
                      checked={
                        x?.find((s) => s == data?.user_id)
                          ? 1
                          : examMarks?.postMarks?.find(
                              (s) => s.student_id == data?.user_id
                            )?.status == "PUBLISHED"
                          ? 1
                          : examMarks?.postMarks?.find(
                              (s) => s.student_id == data?.user_id
                            )?.status == "ACTIVE"
                          ? 0
                          : 0
                      }
                    />
                  </td>
                </tr>
              );
            })
          : studentLoadingMsg}
      </tbody>
    </table>
  );
}

export default EnggResults;
