//Base Url
// export const BASE_URL = "http://localhost:4000";

// Main url
export const BASE_URL = "https://server.swaminarayanuniversity.ac.in";

////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
///////////////////AWS FILE UPLOADER CONSTANTS//////////////////
////////////////////////////////////////////////////////////////f
////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////

// aabbkbbbfvybssbtaoaaataaadabbbmakgaboapbbbboabvqhbbzbbkapaavbbeghacamdpaaqbqabbjbmkajasaabbwabrbfuyaazbha;

export const AWS_URL_GENERATOR = `${BASE_URL}/api/asset/url`; //post Request

////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
///////////////////AWS FILE UPLOADER CONSTANTS//////////////////
///////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////

//Login
export const LOGIN = `${BASE_URL}/api/user/`;

//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
////////////////////////////////HR////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////

/////////////////
/////////////////
//employee//////
////////////////
////////////////

//Staff CRUD
export const EMPLOYEE = `${BASE_URL}/api/hr/employee`; //get put and post

//Staff Placement
export const EMPLOYEE_PLACEMENT = `${BASE_URL}/api/employee/placement`; //CRU

//Staff Employment Terms
export const EMPLOYEE_EMPLOYEMENT_TERMS = `${BASE_URL}/api/employee/employment-terms`; //CRU

//Staff Experience
export const EMPLOYEE_EXPERIENCE = `${BASE_URL}/api/employee/experience`; //CRU

//Staff Experience
export const EMPLOYEE_EDUCATION = `${BASE_URL}/api/employee/education`; //CRU

//Staff Experience
export const EMPLOYEE_LEGAL_DOCUMENTS = `${BASE_URL}/api/employee/legal-document`; //CRU

//Staff Training
export const EMPLOYEE_TRAINING = `${BASE_URL}/api/employee/training`; //CRU

//Staff Training
export const EMPLOYEE_PUBLICATION = `${BASE_URL}/api/employee/publication`; //CRU

/////////////////
/////////////////
//employer//////
////////////////
////////////////

//all Data

export const ALL_DATA = `${BASE_URL}/api/hr/data/getall`; //get

//COLLEGE Type
export const COLLEGE_TYPE = `${BASE_URL}/api/hr/collegetype`; //get , put and post

//Program
export const PROGRAM = `${BASE_URL}/api/hr/program`; //get , put and post

//Specialization
export const SPECIALIZATION = `${BASE_URL}/api/hr/specialization`; //get , put and post

//COLLEGEs
export const COLLEGES = `${BASE_URL}/api/hr/college`; //get , put and post

//COLLEGE Specialization
export const COLLEGE_SPECIALIZATION = `${BASE_URL}/api/hr/collegespecialization`; //get , put and post

//job Roles
export const JOB_POSITIONS = `${BASE_URL}/api/hr/jobroles`; //get, put and post

//Department
export const DEPARTMENTS = `${BASE_URL}/api/hr/department`; //get , put and post

//Trainer
export const TRAINER = `${BASE_URL}/api/hr/trainer`; //get , put and post

//Documetn Category
export const DOCUMENT_CATEGORY = `${BASE_URL}/api/hr/document-category`;

/////////////////
/////////////////
//Attendence/////
/////////////////
/////////////////

//get and add Attendance
export const EMPLOYEE_ATTENDANCE = `${BASE_URL}/api/employee/attendance`; //CRU

//////////////////////////
//////////////////////////
//Documeny Management/////
//////////////////////////
//////////////////////////

//Document Workfolw
export const EMPLOYEE_DOCUMENT_APPROVAL_WORKFLOW = `${BASE_URL}/api/hr/document-workflow`; //CRU

//Document Workfolw
export const EMPLOYEE_DOCUMENT_MANAGEMENT = `${BASE_URL}/api/employee/document`; //CRU

//////////////////////////
//////////////////////////
// //Leave Management/////
//////////////////////////
//////////////////////////

//Leave Get All
export const LEAVE_GET_ALL = `${BASE_URL}/api/hr/leavemanagement`;

//Earning Poolicy
export const LEAVE_EARNING_POLICY = `${BASE_URL}/api/hr/earning-policy`;

//Earning Poolicy
export const LEAVE_LEAVE_TYPE = `${BASE_URL}/api/hr/leave-type`;

//Holiday Type
export const LEAVE_HOLIDAY_TYPE = `${BASE_URL}/api/hr/holiday-type`;

//Holiday List
export const LEAVE_HOLIDAY_LIST = `${BASE_URL}/api/hr/holiday`;

//Entitlements
export const LEAVE_ENTITLEMENT = `${BASE_URL}/api/employee/entitlement`;

//Applications
export const LEAVE_APPLICATION = `${BASE_URL}/api/employee/leave-application`;

//////////////////////////
//////////////////////////
// //Leave Management/////
//////////////////////////
//////////////////////////

//Team Discussions
export const TEAM_DISCUSSION = `${BASE_URL}/api/hr/team-discussion`;

export const TEAM_CHATS = `${BASE_URL}/api/hr/discussion-reply`;

//Team Announcements
export const TEAM_ANNOUNCEMENTS = `${BASE_URL}/api/hr/team-announcement`;

//Document Sharing
export const TEAM_DOC_SHARING = `${BASE_URL}/api/hr/team-document`;

//////////////////////////
//////////////////////////
///Pay Roll Management////
//////////////////////////
//////////////////////////

//Pay ROll Get ALl
export const PAYROLL_GETALL = `${BASE_URL}/api/hr/payroll/getAll`;

//Pay ROll Salary Adjustment
export const PAYROLL_SALARY_ADJUST = `${BASE_URL}/api/employee/salary-adjustment`;

//Pay ROll Pay Slip
export const PAYROLL_PAY_SLIP = `${BASE_URL}/api/employee/payslip`;

//Pay ROll Bonus
export const PAYROLL_BONUS = `${BASE_URL}/api/hr/payroll/bonus`;

//Pay ROll Earning
export const PAYROLL_EARNING = `${BASE_URL}/api/hr/payroll/earning`;

//Pay ROll Deduction
export const PAYROLL_DEDUCTION = `${BASE_URL}/api/hr/payroll/deduction`;

//Pay ROll Statutory Contribution
export const PAYROLL_STATUTORY_CONTRIBUTION = `${BASE_URL}/api/hr/payroll/statutorycontribution`;

//Pay ROll EMPLOYEE PAYSLIP
export const PAYROLL_EMPLOYEE_PAYSLIP = `${BASE_URL}/api/employee/getpayslip`;

//Workflow
export const HR_WORKFLOW = `${BASE_URL}/api/hr/approval-workflow`;

///////////////////////////////////
///////////////////////////////////
///////////////////////////////////
///////////////////////////////////
///////////////////////////////////
/////////////Employee/////////////
/////////////////////////////////
/////////////////////////////////
/////////////////////////////////
/////////////////////////////////
/////////////////////////////////
/////////////////////////////////

//Basic Data
export const EMPLOYEE_DETAILS_BASIC = `${BASE_URL}/api/employee/details`;

////////////////////////////////////
////////////////////////////////////
////////////////////////////////////
////////////////////////////////////
////////////////////////////////////
////////////////////////////////////
///////////Student//////////////////
////////////////////////////////////
////////////////////////////////////
////////////////////////////////////
////////////////////////////////////
////////////////////////////////////
////////////////////////////////////

//Admission Enquirry
export const STUDENT_ADMISSION_ENQUIRRY = `${BASE_URL}/api/frontOffice/admission-enquiry`;

export const STUDENT_ADMISSION_DETAILS = `${BASE_URL}/api/admission`;

export const STUDENT_ADMISSION = `${BASE_URL}/api/student`;

export const STUDENT_SESSION = `${BASE_URL}/api/student/session`;

export const STUDENT_SESSION_ALL = `${BASE_URL}/api/student/session/all`;

export const STUDENT_SESSION_ALL_INVIGILATORS = `${BASE_URL}/api/student/session/allInvigilators`;
