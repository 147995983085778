import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { ACADEMICS_ADD_SUBJECT } from "../../utils/Academics.apiConst";
import {
  POST_EXAM_ADD_MARKS,
  PRE_EXAM_TIMETABLE,
  POST_EXAM_ADD_MARKS1,
  PRE_EXAM_ENROLL_STUDENT,
  POST_EXAM_ADD_MARKS_BULK1,
} from "../../utils/Examination.apiConst";

function ModalAddMarks(props) {
  //Destructuring props
  const {
    addData,
    showCollege,
    setLoading,
    studentData,
    enrollmentStudents,
    class_examination_id,
  } = props;

  const [enrolledData, setEnrolledData] = useState([]);
  //Main obj
  const [user, setUser] = useState([]);

  const [flag, setFlag] = useState(false);

  //Other States for data management
  const [subOpt, setSubOpt] = useState([]);

  //states holding created time table data
  const [timeTableData, setTimeTableData] = useState([]);

  const [addNew, setAddNew] = useState(2);

  const [addNewId, setAddNewId] = useState("");

  const [editData, setEditData] = useState();

  const [selectedSub, setSelectedSub] = useState("");

  const handleEdit = async () => {
    console.log(user);
    if (user?.filter((s) => s.student_id == editData?.student_id)?.length > 0) {
      editData.id = user?.find((s) => s.student_id == editData?.student_id)?.id;
    }

    console.log(editData, addNewId);
    // return;
    const config = {
      method: "post",
      url: `${POST_EXAM_ADD_MARKS1}?id=${editData?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: {
        note: editData?.note,
        marks: editData?.marks,
        time_table_id: addNewId,
        student_id: editData?.student_id,
        absent: editData?.absent,
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res);
        toast.success("Marks Updated");
        getExamMarks();
        setAddNew(1);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //////get Subject Data
  const getbasicData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${ACADEMICS_ADD_SUBJECT}?college_id=${showCollege}&semester_id=${addData?.semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        setSubOpt(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  //get time table data
  const getData = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_EXAM_TIMETABLE}?class_examination_id=${addData?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res.data.data);
        setTimeTableData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(0);
  };

  const handleSubmit = async () => {
    let arr = [];
    for (let i of enrolledData) {
      let obj = {};
      let existingUser = {};
      if (user?.find((s) => s.student_id == i?.student_id)) {
        obj.id = user?.find((s) => s.student_id == i?.student_id)?.id;
        existingUser = user?.find((s) => s.student_id == i?.student_id);
      }
      obj.time_table_id = addNewId;
      obj.marks = i?.marks
        ? i?.marks
        : existingUser?.marks
        ? existingUser?.marks
        : "";
      obj.note = i?.note
        ? i?.note
        : existingUser?.note
        ? existingUser?.note
        : "";
      obj.absent = i?.absent
        ? i?.absent
        : existingUser?.absent
        ? existingUser?.absent
        : "";
      obj.student_id = i?.student_id;
      arr.push(obj);
    }

    for (const i of arr) {
      if (!i?.marks && !i?.absent)
        return toast.error(`Missing marks for student (${i?.student_id})`);
      if (i?.absent && !i?.note)
        return toast.error(
          `Student (${i?.student_id}) is absent but doesn't added note`
        );
    }
    setLoading(1);

    const config = {
      method: "post",
      url: POST_EXAM_ADD_MARKS_BULK1,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
      data: { data: arr },
    };

    await axios(config)
      .then((res) => {
        toast.success("Success");
        getExamMarks();
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });

    setLoading(0);
  };

  const getExamMarks = async () => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${POST_EXAM_ADD_MARKS}?time_table_id=${addNewId}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((res) => {
        console.log(res.data);
        if (res?.data?.length != 0) {
          setUser(res.data);
        } else {
          const arr = [];
          studentData.forEach((i) => {
            if (
              enrollmentStudents.find(
                (s) =>
                  s?.subject == selectedSub?.id && s?.student_id == i?.user_id
              )
            ) {
              const obj = {
                student_id: i?.user_id,
              };
              arr.push(obj);
            }
          });

          setUser(arr);
        }
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });

    const config1 = {
      method: "get",
      url: `${PRE_EXAM_ENROLL_STUDENT}?class_examination_id=${class_examination_id}&status=ACTIVE&subject=${selectedSub?.id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config1)
      .then((res) => {
        console.log(res.data);
        setEnrolledData(res.data.enrolledStudent);
        setLoading(0);
      })
      .catch((err) => {
        setLoading(0);
        console.log(err);
      });
  };

  ////useEffects
  useEffect(() => {
    if (addData) {
      // setUser({
      //     class_examination_id: addData?.id,
      //     session_id: '',
      //     course_id: '',
      //     date: '',
      //     time_from: '',
      //     time_to: "",
      //     duration: "",
      //     max_marks: '',
      //     min_marks: ''
      // })
      getbasicData();
      getData();
    }
    setAddNew(0);
  }, [addData]);

  useEffect(() => {
    if (addNew && addNewId) {
      getExamMarks();
    }
  }, [addNew, addNewId, studentData]);

  return (
    <div
      className="modal fade"
      id="create"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog mw-100 w-100 ">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
              List
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          {addNew == 2 ? (
            <div className="modal-body">
              <div className="row mb-2">
                <div className="col-12">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setAddNew(1);
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="">Enrollment Number</label>
                    <input
                      type="text"
                      value={editData?.student_id}
                      className="form-control"
                      readOnly={true}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="">Student Name</label>
                    <input
                      type="text"
                      value={
                        studentData?.find(
                          (s) => s?.user_id == editData?.student_id
                        )?.name
                      }
                      className="form-control"
                      readOnly={true}
                      disabled={true}
                    />
                  </div>
                </div>

                <div className="col-md-4"></div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="">Attendance</label>
                    <select
                      name=""
                      id=""
                      value={editData?.absent}
                      onChange={(e) => {
                        setEditData((prev) => ({
                          ...prev,
                          absent: e.target.value,
                        }));
                        if (e.target.value == "true") {
                          setEditData((prev) => ({
                            ...prev,
                            marks: 0,
                          }));
                        }
                      }}
                      className="form-control"
                    >
                      <option value={"false"} selected>
                        Present
                      </option>
                      <option value={"true"}>Absent</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="">marks</label>
                    <input
                      type="number"
                      className="form-control"
                      value={
                        editData?.marks
                          ? editData?.marks
                          : user?.find(
                              (s) => s.student_id == editData?.student_id
                            )?.marks
                      }
                      onChange={(e) => {
                        setEditData((prev) => ({
                          ...prev,
                          marks: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label htmlFor="">remarks</label>
                    <input
                      type="text"
                      className="form-control"
                      value={
                        editData?.note
                          ? editData?.note
                          : user?.find(
                              (s) => s.student_id == editData?.student_id
                            )?.note
                      }
                      onChange={(e) => {
                        setEditData((prev) => ({
                          ...prev,
                          note: e.target.value,
                        }));
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-4 mb-4">
                <div className="row">
                  <div className="col-12  d-flex justify-content-end">
                    <button className="btn btn-success" onClick={handleEdit}>
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : addNew ? (
            <div className="modal-body">
              <button
                className="btn btn-primary"
                onClick={() => {
                  setAddNew(0);
                }}
              >
                Back
              </button>
              <>
                <button
                  className="btn btn-success float-right ml-3"
                  onClick={handleSubmit}
                >
                  Save Marks
                </button>
                <button className="btn btn-success float-right">Approve</button>
              </>
              <table className="table mt-4">
                <thead className="text-center">
                  <tr>
                    <th>Sl No</th>
                    <th>Reg. Number</th>

                    <th>Student Name</th>
                    <th>Subject Name</th>

                    <th>Gender</th>
                    <th>Attendance</th>
                    <th>Marks</th>
                    <th>Note</th>
                    <th>action</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {enrolledData?.map((i, key) => (
                    <tr>
                      <td>{key + 1}</td>
                      <td>{i?.student_id}</td>
                      <td>
                        {
                          studentData?.find((s) => s?.user_id == i?.student_id)
                            ?.name
                        }
                      </td>
                      <td>{selectedSub?.name}</td>
                      <td>
                        {
                          studentData?.find((s) => s?.user_id == i?.student_id)
                            ?.gender
                        }
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          name="absent"
                          id="absent"
                          value={
                            i?.absent
                              ? i?.absent
                              : user?.find((s) => s.student_id == i?.student_id)
                                  ?.absent
                          }
                          onChange={(e) => {
                            if (e.target.checked == true) {
                              i.marks = 0;
                            }
                            i.absent = e.target.checked;
                            setFlag((flag) => !flag);
                          }}
                        />
                        <label htmlFor="absent">Absent</label>
                      </td>
                      <td>
                        <input
                          type="number"
                          name="marks"
                          id=""
                          className="form-control"
                          required
                          value={
                            i?.marks
                              ? i?.marks
                              : user?.find((s) => s.student_id == i?.student_id)
                                  ?.marks
                          }
                          onChange={(e) => {
                            i.marks = e.target.value;
                            setFlag((flag) => !flag);
                          }}
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          name="note"
                          id="note"
                          className="form-control"
                          value={
                            i?.note
                              ? i?.note
                              : user?.find((s) => s.student_id == i?.student_id)
                                  ?.note
                          }
                          onChange={(e) => {
                            i.note = e.target.value;
                            setFlag((flag) => !flag);
                          }}
                        />
                      </td>
                      <td className="d-flex align-items-center justify-content-center">
                        <div
                          className="badge badge-soft-primary p-2 cursor-pointer"
                          data-target="#modal2"
                          data-toggle="modal"
                          onClick={() => {
                            setAddNew(2);
                            setEditData((prev) => ({
                              student_id: i?.student_id,
                            }));
                          }}
                        >
                          <i className="ri ri-edit-line"></i>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="modal-body">
              <div className="row mt-4">
                <div className="col-lg-12  table-responsive ">
                  <table
                    className="table table-bordered nowrap table-hover "
                    id="tab_logic"
                  >
                    <thead>
                      <tr>
                        <th>Subjects</th>
                        <th>Date</th>
                        <th>Time From</th>
                        <th>Time To</th>

                        <th> Marks (Max..)</th>
                        <th>Marks (Min..)</th>
                        <th>Status</th>
                        <th>Enter Marks</th>
                      </tr>
                    </thead>
                    <tbody id="examsub">
                      {/* <div></div> */}
                      {timeTableData?.map((i, key) => (
                        <tr key={key}>
                          <td>
                            {subOpt?.find((s) => s.id == i?.course_id)?.name}
                          </td>
                          <td>{i?.date?.split("T")[0]}</td>
                          <td>{i?.time_from}</td>
                          <td>{i?.time_to}</td>

                          <td>{i?.max_marks}</td>
                          <td>{i?.min_marks}</td>
                          <td>
                            {user[0]?.marks != null || user[0]?.marks != 0 ? (
                              <span class="badge badge-soft-success mr-2">
                                Marks Added
                              </span>
                            ) : (
                              <span class="badge badge-soft-danger mr-2">
                                Marks Not Added
                              </span>
                            )}
                          </td>
                          <td className="d-flex align-items-center justify-content-center">
                            <button
                              className="btn badge badge-light text-success p-1 mr-2"
                              title="Add Marks"
                              onClick={() => {
                                setAddNew(1);
                                setAddNewId(i?.id);
                                setUser([]);
                                setSelectedSub(
                                  subOpt?.find((s) => s.id == i?.course_id)
                                );
                              }}
                            >
                              <i class="ri-add-line"></i> Add
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          {/* <div className="modal-footer">
                        <button
                            type="button"
                            class="btn btn-primary"
                            data-dismiss="modal"
                            aria-label="Close"
                        >Save changes</button>
                    </div> */}
        </div>

        {/* /.modal-content */}
      </div>
      {/* /.modal-dialog */}
    </div>
  );
}

export default ModalAddMarks;
